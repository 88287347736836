.stockperproductlist {
}

.stockperproductlist-listItem {
    display: flex;
}

.stockperproductlist-listItem-warehouse {
    position: relative;
    margin-right: 20px;
    box-shadow: var(--shadow-minimal);
    padding: 10px 10px 10px 16px;
    border-radius: 6px;
    border: 1px solid var(--color-border);
}

.stockperproductlist-listItem-warehouse-bar {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 6px;
    height: 100%;
    border-radius: 6px 0 0 6px;
}

.stockperproductlist-listItem-warehouse-name {
    font-size: 12px;
    color: var(--color-text_secondary);
    margin-bottom: 2px;
}

.stockperproductlist-listItem-warehouse-stocks {
    display: flex;
    font-size: 14px;
}

.stockperproductlist-listItem-warehouse-stock {
    margin-right: 10px;
    color: var(--color-text_primary);
}

.stockperproductlist-listItem-warehouse-stock-label {
    margin-right: 4px;
    color: var(--color-text_tertiary);
    margin-left: 4px;
    font-size: 14px;
}

.stockperproductlist-listItem-warehouse-stock-quantity {
    font-size: 16px;
}
